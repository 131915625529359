<template>
  <div class="outer-wrapper">
    <div class="title-wrapper">
      <span>{{ title }}管理</span>
    </div>
    <div class="inner-wrapper">
      <div class="editor-wrapper">
        <Tabs>
          <TabPane :label="'现有' + title + '数据'" icon="ios-people">
            <query-table
              :columnsFormatData="columnsFormatData"
              askData="periodRiskManage"
              :refreshData="update_count"
              main_width="750px"
            ></query-table>
          </TabPane>
        </Tabs>
      </div>
      <div class="preview-wrapper">
        <Tabs>
          <TabPane :label="'新增' + title + '信息'" icon="md-cloud-upload">
            <div class="form-wrapper">
              <Form
                ref="addItem"
                :model="newItem"
                :rules="ruleValidate"
                :label-width="150"
              >
                <FormItem label="填报公司：" prop="company">
                  <Input v-model="newItem.company" placeholder="" />
                </FormItem>
                <FormItem label="业务板块：" prop="business_section">
                  <Input v-model="newItem.business_section" placeholder="" />
                </FormItem>
                <FormItem label="部门：" prop="department">
                  <Input v-model="newItem.department" placeholder="" />
                </FormItem>
                <FormItem label="专业：" prop="major">
                  <Input v-model="newItem.major" placeholder="" />
                </FormItem>
                <FormItem label="风险点：" prop="risk_content">
                  <Input v-model="newItem.risk_content" placeholder="" />
                </FormItem>
                <FormItem label="风险描述：" prop="description">
                  <Input
                    type="textarea"
                    v-model="newItem.description"
                    placeholder=""
                  />
                </FormItem>
                <FormItem label="可能性L：" prop="pl">
                  <InputNumber v-model="newItem.pl"></InputNumber>
                </FormItem>
                <FormItem label="严重度C：" prop="ic">
                  <InputNumber v-model="newItem.ic"></InputNumber>
                </FormItem>
                <FormItem label="风险值D：" prop="rd" v-show="false">
                  <InputNumber v-model="newItem.rd"></InputNumber>
                </FormItem>
                <FormItem label="风险等级：" prop="risk_level">
                  <Input v-model="newItem.risk_level" placeholder="" />
                </FormItem>
                <FormItem label="防范措施：" prop="solution">
                  <Input v-model="newItem.solution" placeholder="" />
                </FormItem>
                <FormItem label="责任岗位：" prop="post">
                  <Input v-model="newItem.post" placeholder="" />
                </FormItem>
                <FormItem label="责任人：" prop="res_person">
                  <Input v-model="newItem.res_person" placeholder="" />
                </FormItem>
                <FormItem label="备注：" prop="remark">
                  <Input v-model="newItem.remark" placeholder="" />
                </FormItem>
                <FormItem>
                  <Button type="primary" @click="handleSubmit('addItem')"
                    >提交</Button
                  >
                  <Button
                    @click="handleReset('addItem')"
                    style="margin-left: 8px"
                    >重置</Button
                  >
                </FormItem>
              </Form>
              <Spin fix v-show="loadingStatus"></Spin>
            </div>
          </TabPane>
          <TabPane label="批量添加" icon="md-cloud-upload">
            <div class="form-wrapper">
              <div class="title-wrapper">
                <p>
                  请上传包含{{ title }}相关数据的excel文件：
                  <a href="/public_files/templates/PeriodRiskTemplate.xlsx"
                    >(模板文件下载)</a
                  >
                </p>
              </div>
              <file-upload
                target="mulityByExcel"
                :iurl="post_url_tail"
                @handleSuccess="() => this.update_count++"
              ></file-upload>
            </div>
          </TabPane>
        </Tabs>
      </div>
    </div>

    <Modal
      v-model="scModal"
      width="800"
      :mask-closable="false"
      @on-cancel="modal_cancel"
    >
      <p slot="header" class="sc-header">
        <Icon type="ios-list-box" />
        <span>{{ title }}信息修改</span>
      </p>
      <div class="sc-content-wrapper">
        <div class="form-wrapper">
          <Form
            ref="alterItem"
            :model="currentItem"
            :rules="ruleValidate"
            :label-width="150"
          >
            <FormItem label="填报公司：" prop="company">
              <Input v-model="currentItem.company" placeholder="" />
            </FormItem>
            <FormItem label="业务板块：" prop="business_section">
              <Input v-model="currentItem.business_section" placeholder="" />
            </FormItem>
            <FormItem label="部门：" prop="department">
              <Input v-model="currentItem.department" placeholder="" />
            </FormItem>
            <FormItem label="专业：" prop="major">
              <Input v-model="currentItem.major" placeholder="" />
            </FormItem>
            <FormItem label="风险点：" prop="risk_content">
              <Input v-model="currentItem.risk_content" placeholder="" />
            </FormItem>
            <FormItem label="风险描述：" prop="description">
              <Input
                type="textarea"
                v-model="currentItem.description"
                placeholder=""
              />
            </FormItem>
            <FormItem label="可能性L：" prop="pl">
              <InputNumber v-model="currentItem.pl"></InputNumber>
            </FormItem>
            <FormItem label="严重度C：" prop="ic">
              <InputNumber v-model="currentItem.ic"></InputNumber>
            </FormItem>
            <FormItem label="风险值D：" prop="rd" v-show="false">
              <InputNumber v-model="currentItem.rd"></InputNumber>
            </FormItem>
            <FormItem label="风险等级：" prop="risk_level">
              <Input v-model="currentItem.risk_level" placeholder="" />
            </FormItem>
            <FormItem label="防范措施：" prop="solution">
              <Input v-model="currentItem.solution" placeholder="" />
            </FormItem>
            <FormItem label="责任岗位：" prop="post">
              <Input v-model="currentItem.post" placeholder="" />
            </FormItem>
            <FormItem label="责任人：" prop="res_person">
              <Input v-model="currentItem.res_person" placeholder="" />
            </FormItem>
            <FormItem label="备注：" prop="remark">
              <Input v-model="currentItem.remark" placeholder="" />
            </FormItem>
          </Form>
        </div>
      </div>
      <div slot="footer">
        <Button size="large" @click="modal_save('alterItem')">确定</Button>
        <Button size="large" @click="modal_cancel">取消</Button>
      </div>
    </Modal>
  </div>
</template>
<script>
import fileUpload from "@/components/Common/FileUpload.vue";
import queryTable from "@/components/Common/QueryTable.vue";

let userToken = localStorage.getItem("current_user_token");

function itemFac() {
  return {
    id: 0,
    company: "",
    business_section: "",
    department: "",
    major: "",
    risk_content: "",
    description: "",
    pl: 0,
    ic: 0,
    rd: 0,
    risk_level: "",
    solution: "",
    post: "",
    res_person: "",
    remark: "",
  };
}

export default {
  name: "periodRiskManage",
  components: {
    queryTable,
    fileUpload,
  },
  props: {},
  data() {
    return {
      title: "风险分级管控",
      post_url_tail: "/period_risk_alter",
      columnsFormatData: [
        {
          title: "填报公司",
          key: "company",
          align: "center",
          width: 110,
        },
        {
          title: "业务板块",
          key: "business_section",
          align: "center",
          width: 90,
        },
        {
          title: "风险点",
          key: "risk_content",
          align: "center",
          width: 100,
        },
        {
          title: "风险描述",
          key: "description",
          align: "center",
        },
        {
          title: "操作",
          align: "center",
          width: 130,
          render: (h, params) => {
            return h("div", [
              h(
                "Button",
                {
                  props: {
                    size: "small",
                  },
                  style: { color: "green" },
                  on: {
                    click: () => {
                      this.itemEdit(params.row);
                    },
                  },
                },
                "修改"
              ),
              h(
                "Button",
                {
                  props: {
                    size: "small",
                  },
                  style: { color: "red", marginLeft: "5px" },
                  on: {
                    click: () => {
                      this.confirmBox(params.row);
                    },
                  },
                },
                "删除"
              ),
            ]);
          },
        },
      ],
      ruleValidate: {
        company: [
          {
            required: true,
            type: "string",
            message: "请输入填报公司名称！",
            trigger: "blur",
          },
        ],
        business_section: [
          {
            required: true,
            type: "string",
            message: "请输入业务板块名称！",
            trigger: "blur",
          },
        ],
        department: [
          {
            required: true,
            type: "string",
            message: "请输入部门！",
            trigger: "blur",
          },
        ],
        major: [
          {
            required: true,
            type: "string",
            message: "请输入专业！",
            trigger: "blur",
          },
        ],
        risk_content: [
          {
            required: true,
            type: "string",
            message: "请输入风险点！",
            trigger: "blur",
          },
        ],
        description: [
          {
            required: true,
            type: "string",
            message: "请输入风险描述！",
            trigger: "blur",
          },
        ],
        可能性L: [
          {
            required: true,
            type: "number",
            message: "请输入可能性L！",
            trigger: "blur",
          },
        ],
        严重度C: [
          {
            required: true,
            type: "number",
            message: "请输入严重度C！",
            trigger: "blur",
          },
        ],
        // 风险值D: [
        //   {
        //     required: true,
        //     type: "number",
        //     message: "请输入风险值D！",
        //     trigger: "blur",
        //   },
        // ],
        risk_level: [
          {
            required: true,
            type: "string",
            message: "请输入风险等级！",
            trigger: "blur",
          },
        ],
        solution: [
          {
            required: true,
            type: "string",
            message: "请输入对策建议！",
            trigger: "blur",
          },
        ],
        post: [
          {
            required: true,
            type: "string",
            message: "请输入责任岗位！",
            trigger: "blur",
          },
        ],
        res_person: [
          {
            required: true,
            type: "string",
            message: "请输入责任人！",
            trigger: "blur",
          },
        ],
      },
      option: {},
      activeOption: {},
      update_count: 0,
      loadingStatus: false,
      newItem: itemFac(),
      currentItem: itemFac(),
      scModal: false,
    };
  },
  watch: {},
  computed: {},
  created() {
    // this.getStaticOption();
    // this.getActiveOption();
  },
  methods: {
    // getStaticOption() {
    //   this.$axios
    //     .post(this.$url + "/static_option", {
    //       userToken: userToken,
    //       askData: "resultRiskManage",
    //     })
    //     .then((response) => {
    //         this.option = response.data.res_record;
    //     });
    // },
    // getActiveOption() {
    //   this.$axios
    //     .post(this.$url + "/active_option", {
    //       userToken: userToken,
    //       askData: "lineList",
    //     })
    //     .then((response) => {
    //       this.activeOption = { lineChoices: response.data.res_record };
    //     });
    // },
    handleReset(name) {
      this.$refs[name].resetFields();
    },
    handleSubmit(name) {
      this.$refs[name].validate((valid) => {
        if (valid) {
          let form = new FormData();
          form.append("userToken", userToken);
          form.append("askData", "addItem");
          form.append("newItem", JSON.stringify(this.newItem));
          this.loadingStatus = true;
          let config = {
            headers: { "Content-Type": "multipart/form-data" },
          };
          this.$axios
            .post(this.$url + this.post_url_tail, form, config)
            .then((response) => {
              this.loadingStatus = false;
              if (response.data.stat === "ok") {
                this.$Message.success(this.title + "添加成功！");
                this.update_count++;
                this.handleReset(name);
              } else {
                this.$Message.error(response.data.error_info);
              }
            })
            .catch((error) => {
              this.loadingStatus = false;
              this.$Message.error(error + "未知错误！");
            });
        } else {
          this.$Message.error("填入信息错误!");
        }
      });
    },
    modal_save(name) {
      this.$refs[name].validate((valid) => {
        if (valid) {
          let form = new FormData();
          form.append("userToken", userToken);
          form.append("askData", "alterItem");
          form.append("currentItem", JSON.stringify(this.currentItem));
          this.loadingStatus = true;
          let config = {
            headers: { "Content-Type": "multipart/form-data" },
          };
          this.$axios
            .post(this.$url + this.post_url_tail, form, config)
            .then((response) => {
              this.loadingStatus = false;
              if (response.data.stat === "ok") {
                this.$Message.success(this.title + "修改成功！");
                this.update_count++;
                this.scModal = false;
              } else {
                this.$Message.error(response.data.error_info);
              }
            })
            .catch((error) => {
              this.loadingStatus = false;
              this.$Message.error(error + "未知错误！");
            });
        } else {
          this.$Message.error("填入信息错误!");
        }
      });
    },
    modal_cancel() {
      this.scModal = false;
    },
    confirmBox(value) {
      this.$Modal.confirm({
        title: "确认删除该条" + this.title + "信息：",
        content:
          "<p>" +
          "风险点：" +
          value.risk_content +
          "</p>" +
          "<p>" +
          "填报公司：" +
          value.company +
          "</p>",
        onOk: () => {
          this.delItem(value.id);
        },
        onCancel: () => {},
      });
    },
    delItem(id) {
      this.$axios
        .post(this.$url + this.post_url_tail, {
          userToken: userToken,
          askData: "delItem",
          pid: id,
        })
        .then((response) => {
          if (response.data.stat === "ok") {
            this.$Message.success("删除成功！");
            this.update_count++;
          }
        })
        .catch((error) => {
          this.$Message.warning(error + "网络错误！操作失败。");
        });
    },
    itemEdit(value) {
      this.currentItem = itemFac();
      this.currentItem = JSON.parse(JSON.stringify(value));
      this.scModal = true;
    },
  },
};
</script>
<style scoped>
.inner-wrapper {
  width: 100%;
  display: flex;
  display: -webkit-flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: flex-start;
  min-height: 700px;
}

.title-wrapper {
  text-align: center;
  font-size: 20px;
  padding: 16px;
}

.editor-wrapper,
.preview-wrapper {
  margin: 0px 20px 0px;
  width: 750px;
  font-size: 14px;
  text-align: center;
  padding: 10px 0;
}

.form-wrapper {
  text-align: left;
  border: 1px solid #eee;
  padding: 80px 120px 0px 50px;
  min-height: 600px;
  position: relative;
}

.sc-content-wrapper {
  padding: 0 50px;
  color: #333;
  font-size: 14px;
}
.choosed-product-wrapper {
  min-height: 100px;
  padding: 10px 20px;
  border: 1px solid #eee;
  box-shadow: 1px 1px 1px #eee;
  margin: 20px 0;
}

.sc-content-wrapper td:first-child {
  text-align: right;
  padding-right: 10px;
}

.sc-content-wrapper td {
  height: 45px;
  color: #777;
}

.sub-content-wrapper {
  margin: 10px 0;
  border: 1px solid #eee;
  position: relative;
}

.sub-content-title {
  position: absolute;
  left: 0;
  overflow: hidden;
  height: 100%;
  writing-mode: vertical-lr;
  text-align: center;
  background: #f5f5f5;
  letter-spacing: 10px;
  text-indent: 10px;
  padding: 12px 10px;
}

.table-wrapper {
  padding: 22.5px 20px;
  margin-left: 50px;
}

.fast-button {
  cursor: pointer;
}

input::-webkit-input-placeholder {
  color: #ccc;
  text-align: center;
}

input:-ms-input-placeholder {
  color: #ccc;
  text-align: center;
}

input::-moz-placeholder {
  color: #ccc;
  text-align: center;
}
tr.modal-product-choosed-tr {
  border-bottom: 1px solid #ddd;
}

tr.modal-product-choosed-tr:last-child {
  border: none;
}
</style>